<template>
  <div>
    <b-modal
      ref="modal-cancel"
      cancel-variant="outline-secondary"
      ok-title="Continuar"
      cancel-title="Sair"
      centered
      :title="`Cancelar Gravação ${cancelItem ? cancelItem.id : ''}`"
      @ok="onClickConfirmCancel"
    >
      <b-form>
        <b-form-group label="Motivo *">
          <b-form-textarea
            rows="7"
            v-model="cancel_description"
            placeholder="Informe uma breve descrição (obrigatório)"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-card>
      <b-row>
        <b-col>
          <h4 class="mb-2">
            {{
              isTransmission
                ? "Gravações Futura Transmissão"
                : "Gravações Gerais"
            }}
          </h4>
        </b-col>
      </b-row>
      <b-overlay
        :show="isloading"
        spinner-variant="primary"
        spinner-type="grow"
        :variant="skin"
      >
        <Table
          :fields="fields"
          :list="list"
          @orderBy="getRecordsOrderBy"
          border="full"
          style="font-size: 10px; min-height: 80vh"
          class="text-nowrap mb-0"
          responsive
        >
          <template #editor_id="data">
            <b-form-group class="mb-0" style="min-width: 150px">
              <v-select
                v-model="data.item.editorSelected"
                :options="editors"
                autocomplete="off"
                :clearable="false"
                @input="changeEditor(data.item)"
                :disabled="
                  !$utils.isPermited('permission.audiovisual.edition.edit')
                "
              >
              </v-select>
            </b-form-group>
          </template>

          <template #id="data">
            <div class="text-center">
              <template
                v-if="
                  $utils.isPermited('permission.audiovisual.edition.cancel')
                "
              >
                <b-badge
                  v-if="
                    data.item.status !== 'Finalizado' &&
                    data.item.status !== 'Cancelado' &&
                    data.item.status !== 'Finalizado com pendencia'
                  "
                  variant="danger"
                  style="font-size: 12px"
                  class="mr-50"
                  @click="onClickCancel(data.item)"
                  v-b-tooltip.hover.top="'Cancelar'"
                >
                  <feather-icon icon="XIcon" />
                </b-badge>
                <b-badge
                  v-else-if="data.item.status == 'Cancelado'"
                  variant="danger"
                  style="font-size: 12px"
                  class="mr-50"
                  @click="onClickConfirmRevertCancel(data.item)"
                  v-b-tooltip.hover.top="'Reverter Cancelamento'"
                >
                  <feather-icon icon="RepeatIcon" />
                </b-badge>
                <b-badge
                  v-else
                  variant="primary"
                  style="font-size: 12px"
                  class="mr-50"
                >
                  <feather-icon icon="XIcon" />
                </b-badge>
              </template>
              <b-badge
                :to="`/audiovisual/recording/view/${data.item.id}`"
                variant="secondary"
                style="font-size: 12px"
              >
                <feather-icon icon="LinkIcon" class="mr-25" />
                <span>{{ data.item.id }}</span>
              </b-badge>
            </div>
          </template>

          <template #subCompanies="data">
            <div
              v-for="(comp, index) in data.item.subCompanies"
              :key="index"
              class="text-center"
              :class="[
                data.item.subCompanies.length > 1 &&
                index != data.item.subCompanies.length - 1
                  ? 'mb-25'
                  : 'm-0',
              ]"
            >
              <b-badge class="d-block" :variant="skin == 'dark' ? 'light-info' : 'light-dark'">
                {{
                  comp
                    .split(" ")
                    .filter((f) => f != "Estratégia")
                    .join(" ")
                }}
              </b-badge>
            </div>
          </template>

          <template #teacher_name="data">
            <div
              :data-text="data.item.teacher_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.teacher_name || "--" }}
            </div>
          </template>

          <template #project_name="data">
            <div
              :data-text="data.item.project_name || '--'"
              class="text-truncate ellipsis"
              style="max-width: 100px"
            >
              {{ data.item.project_name || "--" }}
            </div>
          </template>

          <template #event_name="data">
            <div
              :data-text="data.item.event_name || '--'"
              style="max-width: 100px"
              class="text-truncate ellipsis"
            >
              {{ data.item.event_name || "--" }}
            </div>
          </template>

          <template #status="data">
            <div class="text-center">
              <div v-if="data.item.status">
                <b
                  :style="{
                    color:
                      data.item.status == 'Finalizado'
                        ? 'green'
                        : data.item.status == 'Cancelado'
                        ? 'red'
                        : '',
                  }"
                >
                  <div
                    :data-text="`ID: ${data.item.edition_id}`"
                    class="text-truncate ellipsis"
                  >
                    {{ data.item.status }}
                  </div>
                </b>
              </div>
              <div v-else><b>Pendente</b></div>
            </div>
          </template>

          <template #products="data">
            <div
              v-for="(prod, index) in data.item.products"
              :key="index"
              class="text-center"
              :class="[
                data.item.products.length > 1 &&
                index != data.item.products.length - 1
                  ? 'mb-25'
                  : 'm-0',
              ]"
            >
              <b-badge class="d-block" :variant="skin == 'dark' ? 'light-info' : 'light-dark'">
                {{ prod }}
              </b-badge>
            </div>
          </template>

          <template #status_date="data">
            <div class="text-center">
              <div
                v-if="data.item.status_date"
                :data-text="statusDateFormatted(data.item.status_date)"
                class="ellipsis"
              >
                {{ statusDateFormatted(data.item.status_date).split("-")[0] }}
              </div>
              <div v-else>--</div>

              <div v-if="clockController.some((i) => i.id == data.item.id)">
                {{ clockController.find((f) => f.id == data.item.id).time }}
              </div>
              <div v-else>--</div>
            </div>
          </template>

          <template #priority="data">
            <div class="text-center">
              <b-dropdown
                size="sm"
                variant="outline-primary"
                :disabled="
                  !$utils.isPermited('permission.audiovisual.edition.edit')
                "
                id="dropdown-dropright"
                split
                :split-variant="data.item.prioritySelected.color"
                v-b-tooltip.hover.top="data.item.prioritySelected.label"
              >
                <template #button-content>
                  <feather-icon :icon="data.item.prioritySelected.icon" />
                </template>
                <b-dropdown-item
                  style="font-size: 12px"
                  v-for="priority in priorities"
                  :key="priority.value"
                  @click="changePriority(data.item, priority.value)"
                >
                  <feather-icon
                    :icon="icons[priority.value]"
                    class="align-middle mr-25 mb-25"
                  />
                  {{ priority.label }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </Table>

        <div v-if="more" class="d-flex justify-content-center mt-2">
          <b-button @click="getLoadMore" variant="secondary" pill>
            Carregar Mais
          </b-button>
        </div>
      </b-overlay>

      <!-- Filter -->
      <b-button
        class="btn-filter-appoitment btn-icon rounded-circle"
        :variant="containsFilter ? 'gradient-warning' : 'gradient-info'"
        @click="isActiveFilter = true"
      >
        <feather-icon icon="FilterIcon" size="25" />
      </b-button>
      <b-sidebar
        sidebar-class="sidebar-lg"
        bg-variant="white"
        v-model="isActiveFilter"
        right
        backdrop
        shadow
        no-header
      >
        <template #default="{ hide }" v-if="isActiveFilter">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">Filtros</h5>
            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>
          <b-form class="px-1 mt-1">
            <b-row>
              <b-col cols="12">
                <b-form-group label="ID da Gravação">
                  <b-form-input type="number" v-model="record_id" />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Professor">
                  <v-select
                    v-model="teacherSelected"
                    :options="teachers"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        searchTeacher(res);
                      }
                    "
                    :loading="teacherLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Projeto">
                  <v-select
                    v-model="projectSelected"
                    :options="projects"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        searchProject(res);
                      }
                    "
                    :loading="projectLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Evento">
                  <v-select
                    v-model="eventSelected"
                    :options="events"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        searchEvent(res);
                      }
                    "
                    :loading="eventLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" v-show="true">
                <b-form-group label="Aula">
                  <v-select
                    v-model="courseSelected"
                    :options="courses"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        searchCourse(res);
                      }
                    "
                    :loading="courseLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" v-show="true">
                <b-form-group label="Empresa">
                  <v-select
                    v-model="companySelected"
                    :options="companies"
                    :clearable="true"
                    autocomplete="off"
                    multiple
                    @search="
                      (res) => {
                        searchCompany(res);
                      }
                    "
                    :loading="companiesLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Produto">
                  <v-select
                    v-model="productSelected"
                    :options="products"
                    :clearable="true"
                    autocomplete="false"
                    :loading="productLoading"
                    multiple
                  >
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Status">
                  <v-select
                    v-model="statusSelected"
                    :options="status"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        searchStatus(res);
                      }
                    "
                    :loading="statusLoading"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o Nome</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Editor">
                  <v-select
                    v-model="editorSelected"
                    :options="editors"
                    autocomplete="off"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" class="mb-1">
                <b-form-group label="Prioridade">
                  <v-select
                    v-model="prioritySelected"
                    :options="priorities"
                    autocomplete="off"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="9">
                <b-button @click="filter" variant="gradient-info" block>
                  FILTRAR
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button @click="clearfilter" variant="gradient-primary" block>
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </b-sidebar>
    </b-card>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapState, mapMutations } from "vuex";
import {
  VBToggle,
  BBadge,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormTags,
  BModal,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import _recordMediaService from "@/services/audiovisual/recording-media";
import _editorsService from "@/services/users-service";
import _prioritiesService from "@/services/audiovisual/edition-job-service";
import _editionJobControlService from "@/services/audiovisual/edition-job-control-service";
import _teacherService from "@/services/teachers-service";
import _eventService from "@/services/event-service";
import _productService from "@/services/audiovisual/products-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormTags,
    BModal,
    VBModal,
  },
  props: {
    projectId: {
      type: Object,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState({
      isTransmission: (state) => state.toolbar.validateUpload.is_transmission,
    }),
  },
  data() {
    return {
      containsFilter: false,
      record_id: null,
      prioritySelected: null,
      editorSelected: null,
      teacherSelected: null,
      teachers: [],
      teacherLoading: false,
      projectSelected: null,
      projects: [],
      projectLoading: false,
      eventSelected: null,
      events: [],
      eventLoading: false,
      statusSelected: null,
      status: [],
      statusLoading: false,
      courseSelected: null,
      courses: [],
      courseLoading: false,
      companySelected: null,
      companies: [],
      companiesLoading: false,
      productSelected: null,
      products: [],
      productLoading: false,
      icons: ["ChevronsUpIcon", "ChevronUpIcon", "ChevronDownIcon"],
      isActiveFilter: false,
      more: false,
      isloading: false,
      currentePage: 1,
      search: "",
      size: 0,
      fields: [
        { key: "id", label: "ID", orderBy: { type: "back" } },
        { key: "subCompanies", label: "Empresa" },
        { key: "teacher_name", label: "Professor", orderBy: { type: "back" } },
        { key: "project_name", label: "Projeto", orderBy: { type: "back" } },
        {
          key: "event_name",
          label: "Evento/Aula",
          orderBy: { type: "back" },
        },
        { key: "status", label: "Status", orderBy: { type: "back" } },
        { key: "products", label: "Produto", orderBy: { type: "back" } },
        {
          key: "status_date",
          label: "DT. E/A",
          tooltip: "Data do Evento/Aula",
          orderBy: { type: "back" },
        },
        {
          key: "priority",
          label: "Priori.",
          tooltip: "Prioridade",
          orderBy: { type: "back" },
        },
        {
          key: "editor_id",
          label: "Editor",
          class: "td-min-column",
          orderBy: { type: "back" },
        },
      ],
      list: [],
      editors: [],
      priorities: [],
      autoReload: null,
      ascOrDes: false,
      orderByKey: "",
      cancelItem: null,
      cancel_description: "",
      clockController: [],
    };
  },
  created() {
    this.getPriorities();
    this.getProducts();
    this.resetCurrentSize();
    this.UPDATE_CURRENT_TOOLBAR("validate-upload");
  },
  mounted() {
    this.$root.$on("changeTransmissionView", () => {
      this.filter();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.autoReload) {
      clearInterval(this.autoReload);
      this.UPDATE_CURRENT_TOOLBAR("none");
      next();
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
    }),
    resetCurrentSize() {
      this.size = this.$utils.paginationSize();
    },
    showCancelButton(status) {
      return (
        !(
          status !== "Finalizado" &&
          status !== "Cancelado" &&
          status !== "Finalizado com pendencia"
        ) || status == "Cancelado"
      );
    },
    getPriorities() {
      this.isloading = true;
      _prioritiesService
        .showPriorities()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.priorities = this.$utils.populardropdown(
              res.content,
              "name",
              "value",
              false,
              false,
              false
            );
            this.getEditors();
          } else {
            this.$utils.toastError(
              "Notificação",
              "Nenhum valor de prioridade encontrado."
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getProducts() {
      this.productLoading = true;
      _productService
        .showProductsAll()
        .then((res) => {
          this.products = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.productLoading = false));
    },
    getEditors() {
      _editorsService
        .userTypeEditors()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.editors = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
            let params = this.getParams();
            this.getRecords(this.currentePage, params);
          } else {
            this.$utils.toastError("Notificação", "Nenhum editor encontrado.");
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords(_page, params = null, isAutoLoad = false) {
      this.containsFilter = params ? true : false;
      if (!isAutoLoad) this.isloading = true;
      this.startAutoReload();
      _recordMediaService
        .showRecordingEditor(
          _page,
          isAutoLoad ? this.size : this.$utils.paginationSize(),
          params
        )
        .then((res) => {
          if (res.content) {
            res.content.forEach((element) => {
              element.products = [];
              element.subCompanies = [];

              if (element.recordingMediaFilesRS) {
                element.recordingMediaFilesRS.forEach((medias) => {
                  medias.recordingMediaSubjectRS.forEach((subject) => {
                    if (
                      !element.products.some((p) => {
                        return p == subject.products;
                      })
                    ) {
                      element.products.push(subject.products);
                    }

                    if (
                      !element.subCompanies.some((c) => {
                        return c == subject.company;
                      })
                    ) {
                      element.subCompanies.push(subject.company);
                    }
                  });
                });
              }

              if (element.editor_name && element.editor_id) {
                element.editorSelected = {
                  label: element.editor_name,
                  value: element.editor_id,
                };
              } else {
                element.editorSelected = null;
              }

              if (element.priority_name && element.priority != null) {
                element.prioritySelected = {
                  label: element.priority_name,
                  value: element.priority,
                };
                switch (element.priority) {
                  case 0:
                    element.prioritySelected.icon = "ChevronsUpIcon";
                    element.prioritySelected.color = "danger";
                    break;

                  case 1:
                    element.prioritySelected.icon = "ChevronUpIcon";
                    element.prioritySelected.color = "warning";
                    break;

                  case 2:
                    element.prioritySelected.icon = "ChevronDownIcon";
                    element.prioritySelected.color = "info";
                    break;
                }
              } else {
                element.prioritySelected = {
                  label: "",
                  value: -1,
                  icon: "",
                  color: "",
                };
              }
            });

            if (isAutoLoad) {
              this.list = res.content;
            } else {
              this.more = res.content.length >= this.$utils.paginationSize();
              this.list.push(...res.content);
            }

            this.list.forEach((element) => {
              this.startPlay(element);
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    startAutoReload() {
      if (this.autoReload) {
        clearInterval(this.autoReload);
      }
      this.autoReload = setInterval(() => {
        let params = this.getParams();
        this.getRecords(1, params, true);
      }, 10000);
    },
    getLoadMore(_page) {
      this.size += this.$utils.paginationSize();
      let params = this.getParams();
      this.currentePage += 1;
      this.getRecords(this.currentePage, params);
    },
    getParams() {
      let params = {
        is_transmission: this.isTransmission,
        id: this.record_id ? this.record_id : null,
        teacher_id:
          this.teacherSelected != null ? this.teacherSelected.value : null,
        project_id:
          this.projectSelected != null ? this.projectSelected.value : null,
        product_id: this.productSelected
          ? this.productSelected.map((m) => m.value)
          : null,
        event_id: this.eventSelected != null ? this.eventSelected.value : null,
        course_id:
          this.courseSelected != null ? this.courseSelected.value : null,
        editor_id:
          this.editorSelected != null ? this.editorSelected.value : null,
        edition_status_id:
          this.statusSelected != null ? this.statusSelected.value : null,
        company_id: this.companySelected
          ? this.companySelected.map((m) => m.value)
          : null,
        priority:
          this.prioritySelected != null ? this.prioritySelected.value : null,
        orderByKey: this.orderByKey,
        ascOrDes: this.ascOrDes,
      };

      return params;
    },
    getRecordsOrderBy(_params) {
      this.ascOrDes = _params.ascOrDes;
      this.orderByKey = _params.orderByKey;
      this.filter();
    },
    filter() {
      this.currentePage = 1;
      this.list = [];
      let params = this.getParams();
      this.getRecords(this.currentePage, params);
      this.isActiveFilter = false;
    },
    clearfilter() {
      this.record_id = null;
      this.prioritySelected = null;
      this.editorSelected = null;
      this.teacherSelected = null;
      this.projectSelected = null;
      this.eventSelected = null;
      this.courseSelected = null;
      this.companySelected = [];
      this.productSelected = [];
      this.statusSelected = null;
      this.filter();
    },
    statusDateFormatted(date) {
      if (date) {
        let dateSplited = date.split("T");
        let dateFormatted = dateSplited[0].split("-").reverse().join("/");
        return dateFormatted + " - " + dateSplited[1].substr(0, 8);
      } else {
        return "--";
      }
    },
    changePriority(data, priority) {
      _recordMediaService
        .updatePriority({
          data: {
            id: data.id,
            priority,
          },
        })
        .then((res) => {
          this.priorities.forEach((priority) => {
            if (priority.value == res.content.priority) {
              data.prioritySelected.label = priority.label;
              data.prioritySelected.value = priority.value;
              switch (priority.value) {
                case 0:
                  data.prioritySelected.icon = "ChevronsUpIcon";
                  data.prioritySelected.color = "danger";
                  break;

                case 1:
                  data.prioritySelected.icon = "ChevronUpIcon";
                  data.prioritySelected.color = "warning";
                  break;

                case 2:
                  data.prioritySelected.icon = "ChevronDownIcon";
                  data.prioritySelected.color = "info";
                  break;
              }
            }
          });
          this.$utils.toast("Sucesso!", "Prioridade atualizada");
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    changeEditor(data) {
      _prioritiesService
        .insertEditor({
          data: {
            is_transmission: this.isTransmission,
            recording_media_id: data.id,
            user_id: data.editorSelected.value,
          },
        })
        .then((res) => {
          this.editors.forEach((editor) => {
            if (editor.value == res.content.user_id) {
              data.editorSelected = editor;
              data.edition_id = res.content.id;
              data.editor_id = res.content.user_id;
              data.priority = res.content.priority;

              this.priorities.forEach((priority) => {
                if (priority.value == res.content.priority) {
                  data.prioritySelected.label = priority.label;
                  data.prioritySelected.value = priority.value;
                  switch (priority.value) {
                    case 0:
                      data.prioritySelected.icon = "ChevronsUpIcon";
                      data.prioritySelected.color = "danger";
                      break;

                    case 1:
                      data.prioritySelected.icon = "ChevronUpIcon";
                      data.prioritySelected.color = "warning";
                      break;

                    case 2:
                      data.prioritySelected.icon = "ChevronDownIcon";
                      data.prioritySelected.color = "info";
                      break;
                  }
                }
              });
            }
          });
          this.$utils.toast("Sucesso!", "Editor atualizado");
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.teacherLoading) {
          this.teacherLoading = true;
          setTimeout(() => {
            _teacherService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  res.content.forEach((element) => {
                    this.teachers.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.teacherLoading = false));
          }, 1500);
        }
      }
    },
    searchProject(_search) {
      if (_search && _search.length >= 3) {
        if (!this.projectLoading) {
          this.projectLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionProject(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.projects = [];
                  res.content.forEach((element) => {
                    if (
                      this.projects.some((s) => s.value == element.project_id)
                    )
                      return;
                    this.projects.push({
                      value: element.project_id,
                      label: element.project_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.projectLoading = false));
          }, 1500);
        }
      }
    },
    searchEvent(_search) {
      if (_search && _search.length >= 3) {
        if (!this.eventLoading) {
          this.eventLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionEvent(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.events = [];
                  res.content.forEach((element) => {
                    this.events.push({
                      value: element.event_id,
                      label: element.event_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.eventLoading = false));
          }, 1500);
        }
      }
    },
    searchCourse(_search) {
      if (_search && _search.length >= 3) {
        if (!this.courseLoading) {
          this.courseLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionCourse(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.courses = [];
                  res.content.forEach((element) => {
                    this.courses.push({
                      value: element.course_id,
                      label: element.course_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.courseLoading = false));
          }, 1500);
        }
      }
    },
    searchCompany(_search) {
      if (_search && _search.length >= 3) {
        if (!this.companiesLoading) {
          this.companiesLoading = true;
          setTimeout(() => {
            _recordMediaService
              .autoCompleteEditionCompany(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.companies = [];
                  res.content.forEach((element) => {
                    this.companies.push({
                      value: element.company_id,
                      label: element.company_name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.companiesLoading = false));
          }, 1500);
        }
      }
    },
    searchStatus(_search) {
      if (_search && _search.length >= 3) {
        if (!this.statusLoading) {
          this.statusLoading = true;
          setTimeout(() => {
            _prioritiesService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.status = [];
                  res.content.forEach((element) => {
                    this.status.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                } else if ("PENDENTE".includes(_search.toUpperCase())) {
                  this.status = [];
                  this.status.push({
                    value: 0,
                    label: "Pendente",
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.statusLoading = false));
          }, 1500);
        }
      }
    },
    startPlay(record) {
      if (this.clockController.some((i) => i.id == record.id)) return;
      if (!record.status_date) return;

      let payload = {
        id: record.id,
        time: "00:00hs",
        timeBegan: null,
        started: null,
        running: false,
      };

      payload.timeBegan = new Date(record.status_date);
      this.clockRunning(payload);
      payload.started = setInterval(() => {
        this.clockRunning(payload);
      }, 1000);
      payload.running = true;
      this.clockController.push(payload);
    },
    clockRunning(payload) {
      var currentTime = new Date();
      var timeElapsed = new Date(currentTime - payload.timeBegan);
      var hour = timeElapsed.getUTCHours();
      var min = timeElapsed.getUTCMinutes();

      payload.time =
        this.zeroPrefix(hour, 2) + ":" + this.zeroPrefix(min, 2) + "hs";
    },
    zeroPrefix(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    onClickCancel(_item) {
      this.cancel_description = null;
      this.cancelItem = _item;
      this.$refs["modal-cancel"].show();
    },
    onClickConfirmCancel() {
      if (!this.cancel_description) {
        this.$utils.toastError("OPS!!!", "Informe o motivo.");
        return;
      }
      this.$swal({
        title: "Cancelar Gravação",
        text: `Deseja continuar com o cancelamento da gravação (${this.cancelItem.id})?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, continuar!",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // APLICAR AÇÃO VIA API AQUI
          const payload = {
            data: {
              description: this.cancel_description,
              editionjob: {
                recording_media_id: this.cancelItem.id,
                is_transmission: this.isTransmission,
              },
            },
          };

          this.loading = true;
          _editionJobControlService
            .cancel(payload)
            .then(() => {
              this.$utils.toast(
                "Notificação",
                "Gravação cancelada com sucesso."
              );
              this.filter();
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.loading = false));
        }
      });
    },
    onClickConfirmRevertCancel(_item) {
      this.$swal({
        title: "Reverter Cancelamento da Gravação",
        text: `Deseja continuar com a reversão do cancelamento da gravação (${_item.id})?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, continuar!",
        cancelButtonText: "Não",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // APLICAR AÇÃO VIA API AQUI
          const payload = {
            data: {
              recording_media_id: _item.id,
            },
          };

          this.loading = true;
          _prioritiesService
            .reverseCancellation(payload)
            .then(() => {
              this.$utils.toast(
                "Notificação",
                "Reversão do cancelamento da gravação com sucesso."
              );
              this.filter();
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
<style>
.td-min-column {
  min-width: 250px;
}

.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}

.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}

.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
  z-index: 100;
}

.select-priority {
  display: block;
  position: absolute;
  top: auto;
  left: 4px;
  right: 5px;
}
</style>
